/**=====================
     Pagination scss
==========================**/
.custom-pagination {
    margin-top: calc(22px + (35 - 22) * ((100vw - 320px) / (1920 - 320)));

    .pagination {
        flex-wrap: wrap;
        gap: calc(6px + (13 - 6) * ((100vw - 320px) / (1920 - 320)));

        .page-item {
            border-radius: 5px;
            overflow: hidden;

            &:not(:first-child) {
                .page-link {
                    margin: 0;
                }
            }

            &.active {
                .page-link {
                    background-color: var(--theme-color);
                    border-color: var(--theme-color);
                    color: $white;
                }
            }

            .page-link {
                color: $content-color;
                border: 1px solid transparent;

                &:hover {
                    border-radius: 5px;
                    border: 1px solid var(--theme-color);
                    background-color: unset;
                }

                &:focus {
                    color: var(--theme-color);
                    background-color: $white;
                    box-shadow: none;
                }
            }
        }
    }
}