/**=====================
     Accordion scss
==========================**/
.accordion-box {
  display: grid;
  gap: 24px;

  .accordion-item {
    border: 1px solid $border-color;
    border-radius: 6px;
    overflow: hidden;

    .accordion-header {
      &.collapsed {
        .accordion-button {
          border-bottom: unset;
        }
      }

      .accordion-button {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        color: $title-color;
        background-color: #f8f8f8;
        box-shadow: unset;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid $border-color;

        &::after {
          @include pos($pos: relative, $content: "\f078");
          @include font;
          background: unset;
          font-size: 16px;
          width: auto;
          height: auto;
          line-height: 1;
          margin: 0;
          color: #777;
        }

        &:focus {
          z-index: 0;
          border-color: transparent;
          outline: 0;
          box-shadow: unset;
        }
      }
    }

    .accordion-body {
      .product-description {
        .ckeditor-content {
          p {
            font-size: 15px;
          }
        }
      }
    }
  }
}