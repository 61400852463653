.theme-color-1 {
  --theme-color: #d99f46;
  --theme-color-rgb: 217, 159, 70;
}

.theme-color-2 {
  --theme-color: #1b365d;
  --theme-color-rgb: 11, 175, 154;
}

.theme-color-3 {
  --theme-color: #239698;
  --theme-color-rgb: 35, 150, 152;
}

.theme-color-4 {
  --theme-color: #6262a6;
  --theme-color-rgb: 35, 150, 152;
}

.theme-color-5 {
  --theme-color: #417394;
  --theme-color-rgb: 65, 115, 148;
}

.theme-color-6 {
  --theme-color: #5f84a0;
}

.theme-color-7 {
  --theme-color: #7b4397;
  --theme-color2: #dc2430;
}

// Vendors
@import "vendors/feather-icon";
@import "vendors/font-awesome";
@import "vendors/slick";

// utils
@import "utils/variables";
@import "utils/mixin/animation";
@import "utils/mixin/common";
@import "utils/mixin/breakpoints";

// components
@import "components/accordion";
@import "components/alert";
@import "components/breadcrumb";
@import "components/button";
@import "components/cookie_bar";
@import "components/counter";
@import "components/form";
@import "components/label";
@import "components/lazyload";
@import "components/loader";
@import "components/modal";
@import "components/nav-tabs";
@import "components/pagination";
@import "components/ratio";
@import "components/slider";
@import "components/tab";
@import "components/tap-to-top";
@import "components/theme-setting";
@import "components/timer";
@import "components/title";
@import "components/tooltip";

// Base
@import "base/reset";
@import "base/typography";

// Layout
@import "layout/banner";
@import "layout/blog";
@import "layout/category";
@import "layout/footer";
@import "layout/header";
@import "layout/home";
@import "layout/item";
@import "layout/nav";
@import "layout/newsletter";
@import "layout/offer";
@import "layout/product";
@import "layout/review";
@import "layout/service";

// Pages
@import "pages/coming-soon";
@import "pages/inner_pages";
@import "pages/shop_page";
@import "pages/product_page";

// Themes
@import "themes/rtl";