/**=====================
     Product Page scss
==========================**/
.pt-25 {
    padding-top: 25px;
}

.review-title-2 {
    border-top: 1px solid $border-color;
    padding-top: calc(13px + (25 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(13px + (25 - 13) * ((100vw - 320px) / (1920 - 320)));

    h4 {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $title-color;
        font-weight: 400;
    }

    p {
        margin-bottom: calc(7px + (13 - 7) * ((100vw - 320px) / (1920 - 320)));
        margin-top: 3px;
        font-size: 15px;
        line-height: 1.6;
        color: $content-color;
    }

    button {
        width: 100%;
        padding: 10px 14px;
        background-color: $light-gray !important;
        color: $content-color !important;
        border: 1px solid #eee !important;
        font-size: 16px;

        &:hover {
            background-color: $light-gray;
            color: $content-color;
            border: 1px solid $border-color;
        }
    }
}

.product-load-more {
    .col-grid-box {
        display: none;
    }
}

.product-title {
    margin-top: 20px;

    h4 {
        font-weight: 600;
        margin-bottom: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0.4px;
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    }

    .checkout-img {
        width: calc(80% + -15 * (100vw - 320px) / 1600);
        height: auto;
    }
}

.vendor-box {
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: $light-gray;

    .vendor-contain {
        @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 10px);
        padding: 7px calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
        background-color: $white;
        align-items: center;

        .vendor-image {
            @include pseudowh($width: 64px, $height: auto);

            img {
                @include pseudowh;
                object-fit: contain;
            }
        }
    }

    .vendor-list {
        margin-top: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)));

        ul {
            @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320))));
            align-items: center;

            [dir="rtl"] & {
                padding-right: 0;
            }

            li {
                display: block;

                .address-contact {
                    @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: 8px);

                    .feather {
                        @include pseudowh($width: 20px, $height: 20px);
                        color: var(--theme-color);
                    }

                    h5 {
                        font-weight: 600;

                        span {
                            font-weight: 400;
                            margin-left: 7px;
                        }
                    }
                }
            }
        }
    }

    .vendor-detail {
        margin: calc(13px + (28 - 13) * ((100vw - 320px) / (1920 - 320))) 0 0;
        line-height: 1.7;
        color: $content-color;
        padding-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-bottom: 1px solid $border-color;
    }
}

.hot-line-number {
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: $light-gray;

    h5 {
        font-weight: 600;
        margin-bottom: 4px;
    }

    h6 {
        color: $content-color;
        margin-bottom: 17px;
    }

    h2 {
        font-weight: 600;
        font-size: 24px;
    }
}

.product-category {
    gap: 25px;
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product-section {
    .product-left-box {
        position: sticky;
        top: 0;
    }

    .left-slider-image {

        .sidebar-image {
            border-radius: 8px;
            overflow: hidden;
            background-color: #f8f8f8;

            img {
                @include pseudowh($width: auto, $height: auto);
                cursor: pointer;
                object-fit: contain;
            }
        }
    }

    .right-box-contain {
        position: sticky;
        top: 10px;
        left: 0;

        @include mq-max(md) {
            text-align: center;
        }

        .offer-top {
            font-weight: 500;
            padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba($danger-color, $alpha: .1);
            border-radius: 5px;
            color: $danger-color;
            display: inline-block;
            margin-bottom: 20px;
        }

        .name {
            font-weight: 700;
            margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.5;
        }

        .price-rating {
            @include flex_common ($dis: flex, $align: center, $justify: space-between);

            &-2 {
                @include mq-max(3xl) {
                    display: block;
                }

                .custom-rate {
                    @include mq-max(3xl) {
                        margin-top: 5px;
                    }
                }
            }

            @include mq-max(md) {
                display: block;
            }

            &-box {
                @include mq-max(3xl) {
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                }

                @include mq-max(xs) {
                    display: block;
                }

                .custom-rate {
                    @include mq-max(3xl) {
                        margin-top: 0 !important;
                    }

                    @include mq-max(xs) {
                        margin-top: 10px !important;
                    }
                }
            }

            .custom-rate {
                @include mq-max(md) {
                    margin-top: 10px;
                    justify-content: center;
                }
            }

            .price {
                font-weight: 600;
                font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));

                del {
                    font-weight: 400;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    margin: 0 calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)));
                }

                span {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                }
            }

            .custom-rate {
                ul {
                    li {
                        .feather {
                            @include pseudowh($width: 16px, $height: 16px);
                        }
                    }
                }

                .review {
                    font-size: 13px;
                    margin-left: 12px;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 12px;
                    }
                }
            }
        }

        .product-contain {
            border-bottom: 1px solid $border-color;
            padding-bottom: 16px;

            p {
                color: $content-color;
                line-height: 1.7;
                margin: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) 0 0;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                width: 80%;

                @include mq-max(md) {
                    width: 100%;
                }
            }
        }

        .product-package {
            .select-package {
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(5px + (13 - 5) * ((100vw - 320px) / (1920 - 320))));

                @include mq-max(md) {
                    justify-content: center;
                }

                [dir="rtl"] & {
                    padding-right: 0;
                }

                li {
                    transition: all 0.3s ease-in-out;
                    min-height: unset;
                    margin: 0;
                    padding: 0;
                    position: relative;

                    a {
                        padding: 6px 11px;
                        border: 1px solid $border-color;
                        border-radius: 4px;
                        display: block;
                        color: $content-color;
                        font-size: 14px;
                        transition: all 0.3s ease-in-out;

                        &.active {
                            border: 1px solid var(--theme-color);
                            background: var(--theme-color2);
                            color: $white;
                        }
                    }
                }

                .form-check-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include pseudowh;
                    float: unset;
                    margin: 0;
                    opacity: 0;
                    cursor: pointer;

                    &:checked {
                        ~.form-check-label {
                            border-color: var(--theme-color);

                            span {
                                color: #fff;
                                background-color: var(--theme-color);
                            }
                        }
                    }
                }

                &.color {
                    li {
                        &.active {
                            opacity: 1;
                            border-color: $title-color;
                        }

                        .form-check-input {
                            &:checked {
                                ~.form-check-label {
                                    opacity: 1;
                                    border-color: #222;

                                    span {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }

                        .form-check-label {
                            opacity: 0.7;
                        }
                    }
                }

                &.circle {
                    li {
                        &:hover {
                            button {
                                color: $title-color;
                                background-color: #f1f0f0;
                            }
                        }

                        .form-check-label {
                            @include flex_common;
                            cursor: pointer;
                            border-radius: 100%;
                            color: $content-color;
                            padding: 3px;
                            border: 1px solid rgba(154, 154, 154, 0.4);
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1;
                            transition: all 0.1s ease-in-out;
                            position: relative;

                            span {
                                transition: all 0.1s ease-in-out;
                                width: calc(30px + (34 - 30) * ((100vw - 320px) / (1920 - 320)));
                                height: calc(30px + (34 - 30) * ((100vw - 320px) / (1920 - 320)));
                                @include flex_common;
                                background-color: $light-gray;
                                color: $title-color;
                                border-radius: 100%;
                            }
                        }
                    }
                }

                &.product-radio-list {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .form-check {
                        margin: 0;
                        padding: 0;
                        min-height: auto;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        &:hover {
                            .form-check-input {
                                border-color: rgba(154, 154, 154, 0.65);
                            }

                            .form-check-label {
                                color: $title-color;
                            }
                        }

                        .form-check-input {
                            cursor: pointer;
                            float: unset;
                            margin: 0;
                            width: 16px;
                            height: 16px;
                            background-color: $light-gray;
                            border: 1px solid rgba(154, 154, 154, 0.4);
                            position: relative;
                            opacity: 1;
                            @include flex_common;

                            &::after {
                                @include pos($pos: relative, $content: "");
                                width: 12px;
                                height: 12px;
                                background-color: var(--theme-color);
                                border-radius: 100%;
                                transition: 0.3s ease-in-out;
                                transform: scale(0);
                            }

                            &:focus {
                                box-shadow: unset;
                            }

                            &:active {
                                filter: unset;
                            }

                            &:checked {
                                background-color: $white;
                                border-color: var(--theme-color);

                                &::after {
                                    transform: scale(1);
                                }

                                ~.form-check-label {
                                    color: $title-color;
                                }
                            }
                        }

                        .form-check-label {
                            line-height: 1;
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 1px;
                            color: $content-color;
                            cursor: pointer;
                        }
                    }
                }

                .form-select {
                    background: linear-gradient(187.77deg, #fafafa 5.52%, $light-gray 94%);
                    border-radius: 7px;
                    border: 1px solid #eee;
                    display: inline-block;
                }

                &.image {
                    li {
                        .form-check-label {
                            cursor: pointer;
                            font-size: 14px;
                            transition: all .3s ease-in-out;
                            border: 1px solid rgba(154, 154, 154, 0.4);
                            border-radius: 6px;
                            padding: 3px;

                            img {
                                width: 65px;
                                border-radius: 4px;
                                cursor: pointer;
                                height: 65px;
                                padding: 0;
                                overflow: hidden;
                            }
                        }
                    }
                }

                &.rectangle {
                    li {
                        transition: all 0.3s ease-in-out;
                        min-height: unset;
                        margin: 0;
                        padding: 0;
                        position: relative;

                        &:hover {
                            button {
                                color: #222;
                                background-color: #f1f0f0;
                            }
                        }

                        &.active {
                            button {
                                color: $white;
                                background-color: var(--theme-color);
                            }
                        }

                        .form-check-label {
                            cursor: pointer;
                            font-size: 14px;
                            transition: all .3s ease-in-out;
                            border: 1px solid rgba(154, 154, 154, 0.4);
                            border-radius: 6px;
                            padding: 3px;

                            span {
                                padding: 6px 11px;
                                background-color: $light-gray;
                                border-radius: 4px;
                                transition: all .3s ease-in-out;
                            }
                        }
                    }
                }

                .disabled {
                    overflow: hidden;
                    position: relative;
                    user-select: none;
                    cursor: default;
                    opacity: 0.6 !important;
                    pointer-events: none;

                    &::before {
                        @include pos;
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                        left: 0;
                        background-color: #ff7272;
                        width: 100%;
                        height: 1px;
                        cursor: default;
                        user-select: none;
                        z-index: 1;
                    }

                    a,
                    button {
                        cursor: default;
                    }

                    .form-check-input:checked~.form-check-label {
                        background-color: #f8f8f8 !important;
                        color: $content-color !important;
                    }
                }
            }
        }

        .note-box {
            @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320))));
            align-items: center;
            margin-top: 20px;

            @include mq-max(md) {
                justify-content: center;
            }

            .compare-button {
                background-color: $light-gray;
            }

            .cart-button {
                animation: shake 150ms 2 linear;
            }

            .product-qty {
                width: auto;
                margin-top: 0;

                @include mq-max(2xs) {
                    width: 160px;
                }

                .input-group {
                    background: linear-gradient(187.77deg, #fafafa 5.52%, $light-gray 94%);
                }
            }
        }

        .buy-now-button {
            margin-top: 20px;
            padding-bottom: 16px;
            border-bottom: 1px solid $border-color;

            button {
                background-color: #FF7272;
            }
        }

        .buy-box {
            @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 11px);
            align-items: center;
            margin-top: 20px;
            border-bottom: 1px solid #ececec;
            padding-bottom: 16px;

            .team-box {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                input {
                    margin-top: -7px;
                    display: block;
                }

                .form-check-label {
                    span {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: underline;
                            margin-left: 3px;
                            color: $title-color;
                        }
                    }
                }
            }

            @include mq-max(md) {
                justify-content: center;
            }

            a {
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))));
                align-items: center;
                color: #777;

                .feather {
                    @include pseudowh($width: 17px, $height: auto);
                }
            }
        }

        .feature-list {
            @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 17px);
            align-items: center;
            margin-top: 20px;
            border-bottom: 1px solid #ececec;
            padding-bottom: 16px;

            .team-box {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                input {
                    margin-top: -7px;
                    display: block;
                }

                .form-check-label {
                    span {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: underline;
                            margin-left: 3px;
                            color: $title-color;
                        }
                    }
                }
            }

            @include mq-max(md) {
                justify-content: center;
            }

            a {
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))));
                align-items: center;
                color: $title-color;

                svg {
                    @include pseudowh($width: 17px, $height: 17px);
                }
            }
        }

        .pickup-box {
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;

            .product-info {
                margin-top: 20px;

                .product-info-list {
                    @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(8px + (17 - 8) * ((100vw - 320px) / (1920 - 320))));
                    align-items: flex-start;
                    text-align: left;
                    display: inline-block;
                    column-count: 2;
                    background-color: #f9f9f9;
                    padding: 15px;
                    border-radius: 12px;
                    width: 80%;

                    @include mq-max(xs) {
                        column-count: 1;
                        padding: 15px;
                    }

                    &-2 {
                        display: flex;

                        @media (max-width: 1500px) {
                            width: 100%;
                        }

                        @media (max-width: 1450px) {
                            column-count: 1;
                            padding: 15px;
                        }

                        @include mq-max(xl) {
                            column-count: 2;
                            display: block;
                            padding-bottom: 4px;

                            li {
                                margin-bottom: 8px;
                            }
                        }

                        @include mq-max(xs) {
                            display: flex;
                            padding-bottom: 15px;

                            li {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &-3 {
                        width: 50%;

                        li {
                            margin-bottom: 7px;

                            @include mq-max(2xs) {
                                margin-bottom: 0;
                            }
                        }

                        @include mq-max(2xl) {
                            width: 80%;
                        }

                        @include mq-max(md) {
                            width: 100%;
                        }

                        @include mq-max(2xs) {
                            display: flex;
                        }
                    }

                    li {
                        padding-left: 13px;
                        width: 100%;
                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                        position: relative;
                        color: #777;

                        @include mq-max(sm) {
                            width: auto;
                            margin-left: 18px;
                        }

                        @include mq-max(xs) {
                            margin-left: unset;
                            width: 100%;
                            padding-left: unset;

                            &::after {
                                content: none !important;
                            }
                        }

                        &::after {
                            @include pos;
                            @include center(vertical);
                            @include pseudowh($width: 5px, $height: 5px);
                            left: 0;
                            background-color: $content-color;
                            border-radius: 100%;
                        }

                        a {
                            margin-left: 5px;
                            color: $title-color;
                            font-weight: 500;

                            +a {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            .pickup-icon {
                i {
                    font-size: 20px;
                    color: var(--theme-color);
                }
            }

            .pickup-detail {
                h4 {
                    width: 90%;
                    font-weight: 400;
                    margin-bottom: 5px;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    line-height: 1.6;

                    @include mq-max(md) {
                        width: 100%;
                    }
                }

                h6 {
                    margin-top: 4px;
                }

                a {
                    margin-top: 0;
                    color: var(--theme-color);
                    display: inline-block;
                }
            }
        }

        .payment-option {
            ul {
                align-items: center;
                @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320))));

                @include mq-max(md) {
                    justify-content: center;
                }

                [dir="rtl"] & {
                    padding-right: 0;
                }
            }
        }

        // .share-option {
        //     padding-bottom: 20px;

        //     ul {
        //         @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 20px);

        //         li {
        //             display: block;

        //             a {
        //                 display: block;
        //                 text-align: center;
        //                 font-size: 17px;
        //                 color: $content-color;
        //             }
        //         }
        //     }
        // }

        .progress-sec {
            border-bottom: 1px solid $border-color;
            padding-bottom: 16px;

            .left-progressbar {
                margin-top: 18px;

                h6 {
                    margin-bottom: 10px;
                }

                .progress {
                    height: 10px;

                    &.danger-progress {
                        .progress-bar {
                            background-color: #ff7272;
                        }
                    }

                    &.warning-progress {
                        .progress-bar {
                            background-color: #ffa53b;
                        }
                    }

                    &.success-progress {
                        .progress-bar {
                            background-color: var(--theme-color);
                        }
                    }
                }

                .progress-bar {
                    background-color: var(--theme-color);
                }
            }
        }
    }

    .product-main {
        .slider-image {
            background-color: #f8f8f8;

            img {
                width: 100%;
            }
        }
    }

    .right-sidebar-box {
        position: sticky;
        top: 10px;

        .product-right-sidebar {
            li {
                +li {
                    margin-top: 0;

                    &::after {
                        top: -14px;
                        width: calc(100% - 103px);
                    }
                }
            }
        }
    }
}

.related-product-2 {
    .related-product {
        .product-title-2 {
            margin-top: 0;

            h4 {
                font-size: calc(21px + (25 - 21) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 600;
                margin-bottom: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .related-box {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 15px;

        @media (max-width: 1577px) {
            flex-wrap: wrap;
        }

        .related-image {
            touch-action: pan-x;
            overflow: auto;
            padding-bottom: 13px;

            >ul {
                display: flex;
                align-items: flex-start;
                flex-wrap: nowrap;
                gap: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));

                >li {
                    width: 100%;
                    min-width: 200px;
                    position: relative;

                    +li {
                        margin: 0 !important;

                        &::before {
                            @include pos($pos: absolute, $content: "\2b");
                            @include center(vertical);
                            @include font;
                            left: calc(-18px + (-26 - -18) * ((100vw - 320px) / (1920 - 320)));
                            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                            color: $content-color;
                        }
                    }

                    a {
                        display: block;
                        overflow: hidden;

                        .image-box {
                            background-color: $light-gray;
                            @include pseudowh($width: calc(136px + (170 - 136) * ((100vw - 320px) / (1920 - 320))), $height: calc(136px + (170 - 136) * ((100vw - 320px) / (1920 - 320))));
                            padding: 10px;
                            @include flex_common;
                            position: relative;
                            border-radius: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320)));

                            .form-check {
                                position: absolute;
                                top: 8px;
                                left: 8px;
                                padding: 0;

                                .checkbox_animated {
                                    &::after {
                                        border: 1px solid #4a5567;
                                        border-radius: 3px;
                                    }
                                }
                            }
                        }

                        .related-content {
                            margin-top: 12px;
                            padding: 0 calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));

                            h5 {
                                font-size: 15px;
                                line-height: 1.2;
                                color: $title-color;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                display: -webkit-box;
                                overflow: hidden;
                            }

                            h6 {
                                margin-top: 5px;
                                color: var(--theme-color);

                                del {
                                    color: $content-color;
                                    margin-left: 4px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }

                    .product-box {
                        .product-detail {
                            a {
                                h6 {
                                    font-size: 16px;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bundle-list {
            gap: 15px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            >ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;

                >li {
                    width: 100%;
                    display: block;

                    .form-check {
                        display: flex;
                        padding: 0;
                        margin-bottom: 0;
                        align-items: center;

                        .checkbox_animated {
                            margin-top: -4px;
                        }

                        .form-check-label {
                            span {
                                font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                                color: $content-color;

                                span {
                                    color: $title-color;
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    &.content {
                        margin-top: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)));

                        h5 {
                            font-size: 17px;
                            font-weight: 600;
                            color: #4a5568;
                        }

                        h4 {
                            margin-top: 8px;
                            font-weight: 700;

                            del {
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                margin-left: 5px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sticky-bottom-cart {
    position: fixed;
    bottom: -160px;
    width: 100%;
    z-index: 9;
    background-color: $white;
    padding: calc(11px + (6 - 11) * ((100vw - 320px) / (1920 - 320))) 0;
    box-shadow: 0 0 3px 1px rgba($title-color, 0.06);
    transition: all 0.3s ease;

    .cart-content {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        @include mq-max(sm) {
            display: block;
        }
    }

    .product-image {
        display: flex;
        align-items: center;

        @include mq-max(sm) {
            display: none;
        }

        img {
            @include pseudowh($width: 60px, $height: 70px);
            object-fit: cover;
            object-position: top;
        }

        .content {
            margin-left: 12px;
            color: $title-color;
            margin-top: 0;

            [dir="rtl"] & {
                margin-left: 0;
                margin-right: 12px;
            }

            @include mq-max(lg) {
                display: none;
            }

            @include mq-max(xs) {
                display: block;
            }

            h5,
            h6 {
                margin-bottom: 0;
            }

            h5 {
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
            }

            h6 {
                font-size: 15px;
                margin-top: 6px;

                del {
                    margin-left: 7px;
                    color: $content-color;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 7px;
                    }
                }

                span {
                    color: var(--theme-color);
                    margin-left: 5px;

                    [dir="rtl"] & {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .selection-section {
        display: flex;
        align-items: center;
        margin: 0 25px 0 auto;

        @include mq-max(sm) {
            display: none;
        }

        .form-control {
            background-color: $light-gray;
            border: 1px solid $light-gray;
            text-transform: capitalize;
            color: $title-color;

            &:focus {
                box-shadow: none;
            }
        }

        .product-qty {
            height: 51px;

            .input-group {
                button {
                    height: 100%;
                }
            }
        }

        .form-group {
            width: 300px;
            margin-left: 10px;
            margin-right: 10px;

            @include mq-max(xl) {
                width: 190px;
            }

            @include mq-max(md) {
                margin-right: 0;
                width: unset;
            }
        }
    }

    .add-btn {
        @include mq-max(sm) {
            @include flex_common;
        }

        a {
            padding: 12px 40px;

            @include mq-max(sm) {
                width: 100%;
                @include flex_common;
            }

            &.wishlist-btn {
                display: none;
                background-color: $light-gray;
                color: $title-color;

                &:hover {
                    background-color: var(--theme-color);
                    border-color: var(--theme-color);
                    color: $white;
                }

                @include mq-max(sm) {
                    display: flex;
                }
            }

            +a {
                @include mq-max(sm) {
                    margin-left: 8px;

                    [dir="rtl"] & {
                        margin-right: 8px;
                        margin-left: unset;
                    }
                }
            }

            i {
                margin-right: 7px;

                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 7px;
                }
            }
        }
    }
}

.stickyCart {
    padding-bottom: 94px;

    .theme-option-2 {
        bottom: calc(74px + (90 - 74) * ((100vw - 320px) / (1920 - 320)));
    }

    @include mq-max(sm) {
        padding-bottom: 0;
    }

    .sticky-bottom-cart {
        bottom: 0;
        transition: all 0.3s ease;
    }

    .recently-purchase {
        &.show {
            bottom: 110px;
        }
    }

    .tap-top {
        bottom: 110px;
    }
}

.theme-product-section {
    .product-left-box {
        .product-title {
            .name {
                font-weight: 700;
                line-height: 1.5;
                margin: 0;
            }

            .title-content-list {
                margin-top: 8px;
                margin-bottom: 18px;

                >li {
                    position: relative;

                    +li {
                        padding-left: 15px;
                        margin-left: 8px;

                        [dir="rtl"] & {
                            padding-left: unset;
                            padding-right: 15px;
                            margin-left: unset;
                            margin-right: 8px;
                        }

                        &::before {
                            @include pos;
                            @include center(vertical);
                            @include pseudowh($width: 4px, $height: 4px);
                            left: 0;
                            background-color: #ddd;
                            border-radius: 100%;

                            [dir="rtl"] & {
                                left: unset;
                                right: 0;
                            }
                        }
                    }

                    .content {
                        color: #777;
                        display: inline-block;
                        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 400;

                        .dark & {
                            color: #ddd;
                        }

                        a {
                            color: $title-color;
                            font-weight: 500;
                            text-decoration: underline;

                            .feather {
                                @include pseudowh($width: 18px, $height: 18px);
                                margin-right: 5px;
                            }


                        }

                        .feather {
                            @include pseudowh($width: 17px, $height: 17px);
                        }
                    }
                }
            }

            p {
                line-height: 1.5;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $content-color;
                margin: 0;
            }
        }

        .theme-option-box {
            margin-top: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
            position: relative;
            overflow: hidden;

            &:hover {
                .theme-image-option {
                    &::after {
                        opacity: 0.3;
                    }

                    .theme-image-icon {
                        opacity: 1;
                    }
                }

                .theme-option-image {
                    opacity: 1;

                    .product-theme-option {
                        li {

                            &:first-child {
                                animation: product-fade 100ms ease-in-out;
                            }

                            &:nth-child(2) {
                                animation: product-fade 200ms ease-in-out;
                            }

                            &:nth-child(3) {
                                animation: product-fade 300ms ease-in-out;
                            }

                            &:nth-child(4) {
                                animation: product-fade 400ms ease-in-out;
                            }

                            &:nth-child(5) {
                                animation: product-fade 500ms ease-in-out;
                            }
                        }
                    }
                }
            }

            .theme-image-option {
                position: relative;
                z-index: 0;
                border-radius: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
                overflow: hidden;

                &::after {
                    @include pos;
                    top: 0;
                    left: 0;
                    background-color: #222;
                    @include pseudowh;
                    z-index: 1;
                    opacity: 0;
                    transition: opacity 0.1s ease-in-out;
                }

                .theme-image-icon {
                    opacity: 0;
                    transition: opacity 0.1s ease-in-out;
                    @include center(both);
                    @include flex_common;
                    @include pseudowh($width: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320))), $height: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320))));
                    border-radius: 100%;
                    background-color: $white;
                    z-index: 2;
                    border: unset;
                    position: absolute;
                    color: $title-color;
                    padding: 0;

                    .feather {
                        @include pseudowh($width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))), $height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))));
                    }
                }
            }

            .theme-option-image {
                position: absolute;
                right: calc(10px + (20 - 10) * ((100vw - 576px) / (1920 - 576)));
                z-index: 3;
                opacity: 0;
                top: calc(10px + (20 - 10) * ((100vw - 576px) / (1920 - 576)));

                @include mq-max(sm) {
                    position: relative;
                    inset: unset;
                    margin-top: 12px;
                }

                .product-theme-option {
                    margin: 0;
                    display: grid;
                    gap: 7px;

                    @include mq-max(sm) {
                        display: flex;
                    }

                    li {
                        position: relative;



                        &:hover {
                            .theme-option-list {
                                opacity: 1;
                                visibility: visible;
                                left: -125px;
                            }
                        }

                        a {
                            &.product-theme-icon {
                                color: var(--theme-color);
                                @include flex_common;
                                @include pseudowh($width: calc(34px + (42 - 34) * ((100vw - 576px) / (1920 - 576))), $height: calc(34px + (42 - 34) * ((100vw - 576px) / (1920 - 576))));
                                border-radius: 5px;
                                position: relative;
                                z-index: 0;
                                background-color: $white;
                                overflow: hidden;

                                @include mq-max(sm) {
                                    background-color: #222222;
                                }

                                // &::before {
                                //     @include pos;
                                //     @include pseudowh;
                                //     background-color: var(--theme-color);
                                //     left: 0;
                                //     top: 0;
                                //     opacity: .1;
                                //     z-index: -1;
                                // }

                                .feather {
                                    @include pseudowh($width: calc(18px + (22 - 18) * ((100vw - 576px) / (1920 - 576))), $height: calc(18px + (22 - 18) * ((100vw - 576px) / (1920 - 576))));
                                }
                            }

                            &.layout-grid {
                                @include flex_common;
                                @include pseudowh($width: calc(34px + (42 - 34) * ((100vw - 576px) / (1920 - 576))), $height: calc(34px + (42 - 34) * ((100vw - 576px) / (1920 - 576))));
                                z-index: 0;
                                color: #fff;
                                overflow: hidden;
                                border-radius: 5px;
                                gap: 5px;
                                position: relative;
                                background-color: var(--theme-color);
                                padding: 0;

                                .feather {
                                    @include pseudowh($width: 18px, $height: 18px);
                                }
                            }

                            .form-check-input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                @include pseudowh;
                                opacity: 0;
                                margin: 0;
                                cursor: pointer;

                                &:checked~.feather {
                                    fill: var(--theme-color);
                                }
                            }
                        }

                        .theme-option-list {
                            position: absolute;
                            @include center(vertical);
                            left: -110px;
                            transition: all 0.3s ease-in-out;
                            opacity: 0;
                            visibility: hidden;

                            ul {
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                box-shadow: -1px 0 10px rgba($title-color, 0.071), 5px 20px 40px rgba($title-color, 0.039);
                                background-color: $white;
                                padding: 10px 6px 10px 12px;
                                border-radius: 6px 0 0 6px;
                                position: relative;

                                &::before {
                                    @include pos;
                                    @include pseudowh($width: 15px, $height: 15px);
                                    border-left: 15px solid $white;
                                    border-top: 21px solid transparent;
                                    border-bottom: 21px solid transparent;
                                    z-index: 1;
                                    @include center(vertical);
                                    right: -15px;
                                    text-shadow: -1px 0 10px rgba($title-color, 0.071), 5px 20px 40px rgba($title-color, 0.039);
                                }

                                li {
                                    a {
                                        display: block;
                                        color: rgba($title-color, 0.839);

                                        &:hover {
                                            color: var(--theme-color);
                                        }

                                        .feather {
                                            @include pseudowh($width: 20px, $height: 20px);
                                            stroke-width: 1.8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .right-box-contain {
        @include mq-max(md) {
            text-align: unset;
        }

        .main-right-box-contain {
            border: 1px solid #ececec;
            border-radius: 6px;
            padding: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))) calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgb(248, 248, 248);

            .dark & {
                border-color: rgba(119, 119, 119, .44);
                background-color: #222222f0;
            }
        }

        .product-contain {
            p {
                width: 100%;
            }
        }

        .product-package {
            +.product-package {
                margin-top: calc(22px + (26 - 22) * ((100vw - 320px) / (1920 - 320)));
            }

            h4 {
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320)));
            }

            .form-select {
                background: linear-gradient(187.77deg, #fafafa 5.52%, $light-gray 94%);
                border-radius: 7px;
                border: 1px solid #eee;
                display: inline-block;
                box-shadow: unset;
                padding: calc(8px + .00375*(100vw - 320px)) calc(12px + 3 * (100vw - 320px) / 1600);
            }
        }

        .price-rating {
            .price {
                font-size: 26px;
            }
        }

        .note-box {
            border-bottom: 1px solid #ececec;
            padding-bottom: 16px;
            margin-top: 15px;
            flex-wrap: wrap;

            .dark & {
                border-color: rgba(119, 119, 119, 0.44);
            }

            .cart-button {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                width: 100%;

                &.border-btn {
                    border: 1px solid var(--theme-color);
                    color: var(--theme-color);
                }

                &.fill-btn {
                    background-color: var(--theme-color);
                    color: #fff;
                    border: 1px solid transparent;
                }
            }
        }

        .deal-timer {
            &.product-deal-timer {
                @media (max-width:400px) {
                    width: 100%;
                }
            }
        }

        .buy-box {
            .dark & {
                border-color: rgba(119, 119, 119, 0.44);
            }

            @include mq-max(md) {
                justify-content: unset;
            }
        }

        .product-rating {
            span {
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 0.5px;
                line-height: 1;
            }
        }

        .pickup-box {
            margin-top: 20px;
            padding: 0;
            border: unset;

            .product-info {
                .product-info-list {
                    &.product-info-list-2 {
                        align-items: unset;
                        text-align: unset;
                        padding: 0;
                        background-color: transparent;

                        @include mq-max(xl) {
                            column-count: unset;
                        }
                    }

                    li {
                        .dark & {
                            color: #ddd;
                        }

                        [dir="rtl"] & {
                            padding-left: unset;
                            padding-right: 13px;
                        }

                        &::after {
                            [dir="rtl"] & {
                                left: unset;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }

        .discount-box {
            background-color: $light-gray;
            padding: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320))) calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
            border-radius: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320)));
            border: 1px solid $border-color;

            .dark & {
                border-color: rgba(119, 119, 119, 0.44);
                background-color: #222323;
            }

            h4 {
                font-weight: 600;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 6px;

                span {
                    color: var(--theme-color);
                    font-weight: 600;
                    text-transform: capitalize;
                    position: relative;
                    padding: 7px calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
                    border-radius: 5px;
                    z-index: 0;

                    &::before {
                        @include pos;
                        @include pseudowh;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        background-color: var(--theme-color);
                        opacity: 0.1;
                        border-radius: 5px;
                    }
                }
            }

            p {
                line-height: 1.5;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: $content-color;
            }

            .discount-btn {
                background: var(--theme-color);
                color: #fff;
                border-radius: 5px;
                border: none;
                font-size: 16px;
                letter-spacing: 1.1px;
                padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(16px + (29 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        .deal-timer {
            &.product-deal-timer {
                ul {
                    background-color: $white;
                }
            }
        }

        .vendor-box {
            padding: 0;
            background-color: transparent;

            .vendor-contain {
                padding: 12px calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320)));
                background-color: $white;
                margin-bottom: 27px;
                border-radius: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320)));

                .vendor-image {
                    width: calc(58px + (72 - 58) * ((100vw - 320px) / (1920 - 320)));
                    height: calc(58px + (72 - 58) * ((100vw - 320px) / (1920 - 320)));
                }
            }

            .vendor-name {
                h5 {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                    margin-bottom: calc(5px + (0 - 5) * ((100vw - 320px) / (1920 - 320)));
                }
            }

            // .vendor-box {
            //     // padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
            //     // background-color: $light-gray;

            //     .vendor-contain {
            //         @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 10px);
            //         padding: 7px calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
            //         background-color: $white;
            //         align-items: center;

            //         .vendor-image {
            //             @include pseudowh($width: 72px, $height: 72px);

            //             img {
            //                 @include pseudowh;
            //                 object-fit: contain;
            //             }
            //         }
            //     }

            //     .vendor-list {
            //         margin-top: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)));

            //         ul {
            //             @include flex_wrap ($dis: flex, $wrap: wrap, $gap: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320))));
            //             align-items: center;

            //             [dir="rtl"] & {
            //                 padding-right: 0;
            //             }

            //             li {
            //                 display: block;

            //                 .address-contact {
            //                     @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: 8px);

            //                     .feather {
            //                         @include pseudowh($width: 20px, $height: 20px);
            //                         color: var(--theme-color);
            //                     }

            //                     h5 {
            //                         font-weight: 600;

            //                         span {
            //                             font-weight: 400;
            //                             margin-left: 7px;
            //                         }
            //                     }
            //                 }
            //             }
            //         }
            //     }

            //     .vendor-detail {
            //         margin: calc(13px + (28 - 13) * ((100vw - 320px) / (1920 - 320))) 0 0;
            //         line-height: 1.7;
            //         color: $content-color;
            //         padding-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
            //         border-bottom: 1px solid $border-color;
            //     }
            // }
        }

        .license-list {
            display: grid;
            gap: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));

            .form-check {
                min-height: unset;
                margin: 0;
                display: flex;
                align-items: center;
                padding: 0;
                position: relative;

                .form-check-input {
                    float: unset;
                    margin: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include pseudowh;
                    opacity: 0;
                    cursor: pointer;

                    &:checked {
                        ~.form-check-label {
                            // border: 1px solid var(--theme-color);

                            &::before {
                                opacity: 0.1;
                            }

                            .circle-box {
                                .circle {
                                    border-color: var(--theme-color);
                                    background-color: $white;

                                    &::before {
                                        transform: translate(-50%, -50%) scale(1);
                                    }
                                }
                            }
                        }
                    }
                }

                .form-check-label {
                    cursor: pointer;
                    position: relative;
                    // border: 1px solid rgba($content-color, 0.169);
                    border-radius: 11px;
                    // padding: 16px;
                    overflow: hidden;
                    z-index: 0;
                    // background-color: $white;
                    width: 100%;
                    transition: all 0.1s ease-in-out;

                    // &:hover {
                    //     border: 1px solid rgba(74, 85, 104, 0.3);

                    //     // .circle-box {
                    //     //     .circle {
                    //     //         &::before {
                    //     //             transform: translate(-50%, -50%) scale(0.8);
                    //     //             background-color: #4a55684a;
                    //     //         }
                    //     //     }
                    //     // }
                    // }

                    // &::before {
                    //     z-index: -1;
                    //     @include pos;
                    //     @include pseudowh;
                    //     top: 0;
                    //     left: 0;
                    //     background-color: var(--theme-color);
                    //     opacity: 0;
                    //     transition: all 0.1s ease-in-out;
                    // }

                    .circle-box {
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        // margin-bottom: 12px;

                        .circle {
                            @include pseudowh($width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))), $height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))));
                            background-color: $white;
                            border-radius: 100%;
                            position: relative;
                            border: 1px solid #ececec;
                            transition: all 0.1s ease-in-out;

                            .dark & {
                                border-color: rgba(119, 119, 119, .44);
                            }

                            &::before {
                                @include pos;
                                @include pseudowh($width: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))), $height: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))));
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%) scale(0);
                                background-color: var(--theme-color);
                                border-radius: 100%;
                                transition: transform 0.1s ease-in-out;
                            }
                        }
                    }

                    .name {
                        margin: 0;
                        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                        @include flex_common ($dis: flex, $align: center, $justify: space-between);
                        width: calc(100% - 7px - calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))));
                        font-weight: 500;
                        color: $content-color;

                        span {
                            color: $title-color;
                        }
                    }

                    .license-content {
                        line-height: 1.5;
                        color: $content-color;
                    }
                }
            }

            .accordion-item {
                background-color: transparent;
                border: unset;

                .accordion-button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    position: relative;
                    border: 1px solid rgba($content-color, 0.169);
                    border-radius: 11px;
                    padding: 16px;
                    overflow: hidden;
                    z-index: 0;
                    background-color: $white;
                    width: 100%;
                    transition: all 0.1s ease-in-out;
                    color: $title-color;
                    box-shadow: none;
                    font-size: 18px;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: unset;
                        top: 0;
                        left: 0;
                        background-color: var(--theme-color);
                        z-index: -1;
                        margin: 0;
                        opacity: 0;
                    }

                    &:not(.collapsed) {
                        border-color: var(--theme-color);

                        .feather {
                            transform: rotate(-180deg);
                        }

                        &::after {
                            opacity: 0.15;
                            transform: unset;
                        }
                    }

                    .feather {
                        transition: all 0.3s ease-in-out;
                        width: 20px;
                        height: 20px;
                    }
                }

                .accordion-collapse {
                    margin-top: 5px;

                    .accordion-body {
                        background-color: $white;
                        border: 1px solid rgba($content-color, 0.169);
                        border-radius: 11px;
                        padding: 16px 20px;

                        &.product-package {
                            .select-package {
                                @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(5px + (13 - 5) * ((100vw - 320px) / (1920 - 320))));

                                @include mq-max(md) {
                                    justify-content: center;
                                }

                                body.rtl & {
                                    padding-right: 0;
                                }

                                li {
                                    border: 1px solid rgba(154, 154, 154, 0.4);
                                    border-radius: 6px;
                                    padding: 3px;
                                    transition: all 0.3s ease-in-out;

                                    a,
                                    button {
                                        padding: 6px 11px;
                                        border: 1px solid $border-color;
                                        border-radius: 4px;
                                        display: block;
                                        color: $content-color;
                                        font-size: 14px;
                                        transition: all 0.3s ease-in-out;
                                        background: transparent;
                                    }

                                    &:hover {
                                        border: 1px solid rgba(154, 154, 154, 0.65);

                                        button,
                                        img {
                                            border-color: rgba(154, 154, 154, 0.65);
                                        }
                                    }

                                    &.active {
                                        border: 1px solid var(--theme-color);
                                        border-radius: 6px;
                                        padding: 3px;

                                        button,
                                        img {
                                            background: var(--theme-color);
                                            color: $white;
                                            font-weight: 600;
                                        }
                                    }

                                    &.disabled {
                                        position: relative;
                                        user-select: none;
                                        cursor: default;
                                        opacity: 0.6 !important;
                                        pointer-events: none;

                                        &::before {
                                            @include pos;
                                            top: 50%;
                                            transform: translateY(-50%) rotate(45deg);
                                            left: 0;
                                            background-color: #ff7272;
                                            width: 100%;
                                            height: 1px;
                                            cursor: default;
                                            user-select: none;
                                        }

                                        a,
                                        button {
                                            cursor: default;
                                        }
                                    }
                                }
                            }

                            .color {
                                li {
                                    opacity: 0.7;

                                    &.active {
                                        opacity: 1;
                                        border-color: $title-color;
                                    }

                                    button {
                                        width: 36px !important;
                                        height: 36px !important;
                                    }
                                }
                            }

                            .circle {
                                li {
                                    border: 1px solid rgba(154, 154, 154, 0.4);
                                    border-radius: 100% !important;
                                    padding: 3px;
                                    transition: all 0.3s ease-in-out;

                                    &:hover {
                                        button {
                                            color: #222;
                                            background-color: #f1f0f0;
                                        }
                                    }

                                    &.active {
                                        button {
                                            color: $white;
                                            background-color: var(--theme-color);
                                        }
                                    }

                                    button {
                                        width: 35px;
                                        height: 35px;
                                        background-color: #f8f8f8;
                                        border-radius: 100%;
                                        color: $content-color;
                                        padding: 0;
                                        border: unset;
                                        font-weight: 400;
                                        @include flex_common;
                                    }
                                }
                            }

                            .form-check {
                                margin: 0;
                                padding: 0;
                                min-height: auto;
                                display: flex;
                                align-items: center;
                                gap: 8px;

                                &:hover {
                                    .form-check-input {
                                        border-color: rgba(154, 154, 154, 0.65);
                                    }

                                    .form-check-label {
                                        color: $title-color;
                                    }
                                }

                                +.form-check {
                                    margin-left: 15px;
                                }

                                .form-check-input {
                                    @include flex_common;
                                    cursor: pointer;
                                    float: unset;
                                    margin: 0;
                                    width: 16px;
                                    height: 16px;
                                    background-color: #f8f8f8;
                                    border: 1px solid rgba(154, 154, 154, 0.4);
                                    position: relative;

                                    &::after {
                                        @include pos($pos: relative, $content: "");
                                        width: 12px;
                                        height: 12px;
                                        background-color: var(--theme-color);
                                        border-radius: 100%;
                                        transition: 0.3s ease-in-out;
                                        transform: scale(0);
                                    }

                                    &:focus {
                                        box-shadow: unset;
                                    }

                                    &:active {
                                        filter: unset;
                                    }

                                    &:checked {
                                        background-color: $white;
                                        border-color: var(--theme-color);

                                        &::after {
                                            transform: scale(1);
                                        }

                                        ~.form-check-label {
                                            color: $title-color;
                                        }
                                    }
                                }

                                .form-check-label {
                                    line-height: 1;
                                    font-size: 16px;
                                    font-weight: 600;
                                    margin-top: 1px;
                                    color: $content-color;
                                    cursor: pointer;
                                }
                            }

                            .form-select {
                                background: linear-gradient(187.77deg, #fafafa 5.52%, #f8f8f8 94%);
                                border-radius: 7px;
                                border: 1px solid #eee;
                                display: inline-block;
                            }

                            .image {
                                li {
                                    border: 1px solid rgba(154, 154, 154, 0.4);
                                    border-radius: 6px;
                                    padding: 3px;
                                    width: 65px;
                                    height: 65px;
                                    @include flex_common;
                                    border: 1px solid #eee;
                                    border-radius: 5px;
                                    padding: 5px;

                                    &.active {
                                        img {
                                            background: transparent;
                                        }
                                    }

                                    // img {
                                    //     // width: 65px;
                                    //     border-radius: 4px;
                                    //     cursor: pointer;
                                    //     // height: 65px;
                                    //     padding: 0;
                                    //     overflow: hidden;
                                    // }
                                }
                            }

                            .rectangle {
                                li {
                                    border: 1px solid rgba(154, 154, 154, 0.4);
                                    border-radius: 6px;
                                    padding: 3px;

                                    &:hover {
                                        button {
                                            color: #222;
                                            background-color: #f1f0f0;
                                        }
                                    }

                                    &.active {
                                        button {
                                            color: $white;
                                            background-color: var(--theme-color);
                                        }
                                    }

                                    button {
                                        background-color: #f8f8f8;
                                        border: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-label-group {
    position: absolute;
    top: 24px;
    left: 0;

    .product-label-tag {
        padding: 5px 13px;
        background-color: #ff7272;
        font-size: 14px;
        color: $white;
        font-weight: 500;
        position: relative;
        z-index: 1;
        width: max-content;

        &::before {
            @include pos;
            width: 15px;
            height: 15px;
            top: 0;
            right: -15px;
            border-bottom: solid 15px #ff7272;
            border-left: solid 15px transparent;
            border-right: solid 15px transparent;
            transform: rotate(180deg);
            z-index: 1;
        }

        &::after {
            @include pos;
            width: 15px;
            height: 15px;
            top: 16px;
            right: -15px;
            border-bottom: solid 15px #ff7272;
            border-left: solid 15px transparent;
            border-right: solid 15px transparent;
            transform: rotate(0);
            z-index: 1;
        }

        span {
            font-weight: 700;
        }

        &.warning-label-tag {
            background-color: #ffba00;

            &::before,
            &::after {
                border-bottom-color: #ffba00;
            }
        }

        +.product-label-tag {
            margin-top: 13px;
        }
    }
}

.product-count ul {
    display: inline-flex;
    align-items: center;
    gap: 3px 15px;
    margin-bottom: 16px;
    flex-wrap: wrap;

    li {
        display: flex;
        align-items: center;
        gap: 10px;

        .feather {
            stroke: var(--theme-color);
            width: 20px;
            height: 20px;
        }

        h6 {
            font-size: 16px;
        }
    }
}

.share-option {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color;

    ul {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 20px);

        @include mq-max(md) {
            justify-content: center;
        }

        li {
            display: block;

            &:hover {
                a {
                    transform: translateY(-4px);
                }
            }

            a {
                display: block;
                text-align: center;
                font-size: 18px;
                color: $content-color;
                transition: all 0.5s ease;

                &:hover {
                    color: var(--theme-color);
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.dynamic-checkout {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    max-width: 600px;

    >* {
        width: 100%;
    }

    button {
        width: 100%;
        color: #fff;
        text-transform: uppercase;

        &.border-theme-color {
            border: 1px solid var(--theme-color);
            background-color: transparent;
            color: var(--theme-color);
            transition: none;

            .spinning {
                &::before {
                    border-right: 3px solid #1aa488;
                }
            }
        }

        &:hover {
            background-color: var(--theme-color);
            border-color: var(--theme-color);
            color: #fff;
        }

        i {
            animation: ring 4s 0.7s ease-in-out infinite;
            line-height: 1;
            margin-top: -3px;
            font-size: 19px;
        }
    }
}