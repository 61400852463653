/* =====================
    Slick slider scss 
 ========================== */
.category-box {
  background-color: $light-gray;
  margin: 0px auto;
  display: block;
  width: 90%;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320))) 8px;
  z-index: 0;

  &.category-bg {
    background: linear-gradient(149.8deg, #f6f5f2 17.21%, #fbfaf9 79.21%);
  }

  &::after {
    @include pos;
    @include pseudowh;
    top: 0;
    left: 0;
    background-image: url(../images/vegetable/shape.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--theme-color);
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background-color: transparent;

    &::after {
      opacity: 1;
    }

    img {
      filter: invert(1) brightness(100);
    }

    h5 {
      color: $white;
    }
  }

  &-2 {
    &::after {
      background-image: url(../images/cake/bg.jpg);
    }
  }

  img {
    @include pseudowh($width: calc(38px + (45 - 38) * ((100vw - 320px) / (1920 - 320))),
      $height: calc(38px + (45 - 38) * ((100vw - 320px) / (1920 - 320))));
    object-fit: contain;
    margin: 0 auto;
  }

  h5 {
    color: $title-color;
    margin-top: 12px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.shop-box {
  .shop-category-box {
    padding: 20px 10px;
    background-color: $light-gray;
    display: block;
    text-align: center;
    border-radius: 0;
    margin: 0 !important;
    border-right: 1px solid $border-color;

    a {
      display: block;
      width: 100%;

      .shop-category-image {
        @include pseudowh($width: 68px, $height: 68px);
        display: inline-block;
        background-color: $light-gray;
        padding: 8px;
        border-radius: 7px;

        img {
          @include pseudowh;
        }
      }

      .category-box-name {
        position: relative;
        width: 90%;
        margin: 10px auto 0;
        background-color: $light-gray;
        color: $title-color;
        border-radius: 4px;

        h6 {
          color: $content-color;
          font-size: 14px;
          font-weight: 500;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }
      }
    }
  }
}

.deal-section {
  .deal-box {
    border-radius: 12px;
    border: 1px solid rgba($black, 0.2);
    padding: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      .category-image {
        img {
          transform: scale(1.05);
        }
      }
    }

    @include mq-max(sm) {
      display: block;
    }

    .category-image {
      width: 60%;

      @include mq-max(sm) {
        width: 100%;
      }

      img {
        width: 80%;
        margin-left: auto;
        transition: all 0.3s ease-in-out;

        [dir="rtl"] & {
          margin-left: unset;
          margin-right: auto;
        }

        @include mq-max(sm) {
          width: 60%;
          margin: 0 auto 30px;
        }
      }
    }

    .buy-box {
      @include flex_common;
      position: absolute;
      top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
      right: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
      background-color: var(--theme-color);
      padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 5px;

      [dir="rtl"] & {
        right: unset;
        left: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
      }

      .iconly-Buy {
        font-size: 24px;
      }
    }

    .deal-detail {
      width: 65%;

      @include mq-max(sm) {
        width: 100%;
      }

      .hot-deal {
        background-color: var(--theme-color);
        display: inline-block;
        padding: 3px 10px;
        border-radius: 50px;
        font-size: 11px;
        color: $white;
        letter-spacing: 0.8px;
        margin-bottom: 10px;
        text-transform: uppercase;

        span {
          line-height: 2.1;
        }
      }

      h5 {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin: 10px 0 8px;
        text-transform: capitalize;
        font-weight: 500;
      }

      .price {
        color: var(--theme-color);
        font-weight: 500;
        margin-bottom: 10px;

        span {
          color: $content-color;
          text-decoration: line-through;
          font-weight: 400;
          transition: 0.3s ease;
        }
      }

      .custom-progressbar {
        border-radius: 50px;
        height: 10px;

        .progress-bar {
          background: var(--theme-color2);
          border-radius: 50px;
        }
      }

      .item {
        color: $content-color;
        margin-top: 18px;

        span {
          color: $title-color;
          font-weight: 500;
        }
      }

      .timer {
        margin-top: 15px;

        ul {
          li {
            .counter {
              font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));

              >div {
                line-height: 1;
              }
            }
          }
        }
      }
    }

    .offer {
      color: $content-color;
      margin: 5px 0 12px;
    }
  }
}

.category-section-2 {
  .category-slider {
    .shop-category-box {
      position: relative;
      padding-top: 14px;

      &:hover {
        a {
          &::before {
            border-radius: 11px;
          }

          img {
            transform: scale(1.04);
          }
        }
      }

      a {
        display: block;
        position: relative;

        &.circle-1 {
          &::before {
            background-color: #fff9d8;
          }
        }

        &.circle-2 {
          &::before {
            background-color: #fff2ec;
          }
        }

        &.circle-3 {
          &::before {
            background-color: #fce9e9;
          }
        }

        &.circle-4 {
          &::before {
            background-color: #fcf1ff;
          }
        }

        &::before {
          @include pos;
          top: -14px;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 100px;
          transition: all 0.3s ease-in-out;
        }

        img {
          position: relative;
          width: calc(87px + (110 - 87) * ((100vw - 320px) / (1920 - 320)));
          margin: 0 auto;
          transition: all 0.3s ease-in-out;
        }
      }

      .category-name {
        position: relative;
        text-align: center;
        z-index: 0;
        margin: 16px auto 0;

        h6 {
          transition: all 0.3s ease-in-out;
          margin: 0 auto;
          font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 0.7px;
          line-height: 1.3;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          -webkit-line-clamp: 3;
          margin-bottom: 0;
          font-weight: 500;
        }
      }
    }
  }
}

.category-section-3 {
  .category-box-list {
    padding: 20px;
    border-radius: 15px;
    background-color: #f1f1f3;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 0;

    &::after {
      @include pos;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(var(--theme-color-rgb), 0.15);
      z-index: -1;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      background-color: $white;

      &::after {
        opacity: 1;
      }

      .category-name {
        h4 {
          font-weight: 700;
          color: var(--theme-color);
        }

        h6 {
          color: $title-color;
        }
      }

      .category-box-view {
        .shop-button {
          opacity: 1;
          bottom: 17px;
        }
      }

      a {
        img {
          opacity: 0.7;
          transform: scale(1.03);
        }
      }
    }

    .category-box-view {
      .shop-button {
        @include center(horizontal);
        background-color: var(--theme-color);
        font-size: 14px;
        padding: 10px 20px;
        color: $white;
        font-weight: 600;
        position: absolute;
        bottom: -40px;
        opacity: 0;

        i {
          margin-left: 6px;
          font-size: 12px;
        }
      }
    }

    .category-name {
      margin-bottom: 20px;
      color: $title-color;
      display: block;

      h4 {
        font-weight: 600;
        transition: all 0.3s ease-in-out;
      }

      h6 {
        margin-top: 5px;
        color: $content-color;
        transition: all 0.3s ease-in-out;
      }
    }

    a {
      display: block;
      position: relative;

      img {
        margin: 0 auto;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.category-section-4 {
  .shop-category-box {
    text-align: center;

    img {
      width: 120px;
      height: 120px;
      object-fit: contain;
      border-radius: 25px;
      margin: 0 auto;
      border: 2px solid $white;
    }

    .category-name {
      margin-top: 14px;

      h6 {
        font-size: 16px;
        color: $white;
        font-weight: 500;
      }
    }
  }
}

.book-category {
  margin-bottom: 9px;

  .book-category-slider {
    width: 97%;
    margin-inline: auto;
  }

  .category-box {
    background-color: $light-gray;
    margin: 0px auto;
    height: calc(135px + (166 - 135) * ((100vw - 320px) / (1920 - 320)));
    width: 81%;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
    padding: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320))) 8px;
    z-index: 0;
    @include flex_common;
    position: relative;

    &:hover {
      img {
        filter: unset;
      }
    }

    &::after {
      content: unset;
    }

    &::before {
      @include pos;
      display: block;
      width: 100%;
      height: 1em;
      background: rgba(0, 0, 0, 0.35);
      border-radius: 100%;
      bottom: -10px;
      filter: blur(5px);
      z-index: -1;
    }

    img {
      z-index: -1;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    h5 {
      color: $white;
      font-weight: 500;
      -webkit-line-clamp: 1;
      margin: 0;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      z-index: 1;
    }
  }
}

.feature-category-panel {
  margin-top: -50px;

  .feature-panel-slider {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(221, 221, 221, 0.549);
    margin: 0 auto;
    width: 100%;

    .cate-box {
      text-align: center;
      padding: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320))) calc(15px + (44 - 15) * ((100vw - 320px) / (1920 - 320)));
      display: block;
      border-radius: 8px;
      background-color: $light-gray;
      position: relative;
      overflow: hidden;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: transparent;

        &:before {
          opacity: 1;
          visibility: visible;
        }

        span {
          color: white;
          font-weight: 500;
        }
      }

      &::before {
        @include pos;
        @include center(both);
        @include pseudowh;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        z-index: -1;
        background-image: linear-gradient(140deg, #1a1c28 0%, #1c1b27 8%, #1e1b27 20%, #211b27 34%, #221924 49%, #241924 64%, #251822 78%, #261720 89%, #27171e 96%, #27161c 100%);
      }

      a {
        background-color: #27202787;
        padding: 16px 25px;
        display: block;
        border-radius: 8px;
      }

      span {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $title-color;
        margin-block: -2px -4px;
        display: block;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }
    }
  }
}

.category-panel {
  margin-top: -50px;

  .category-panel-slider {
    display: flex;
    align-items: center;

    .cate-box {
      position: relative;
      text-align: center;
      display: block;
      background-color: $light-gray;
      padding: calc(19px + (35 - 19) * ((100vw - 320px) / (1920 - 320))) calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: #ddd;
      }

      img {
        width: calc(39px + (45 - 39) * ((100vw - 320px) / (1920 - 320)));
        height: calc(39px + (45 - 39) * ((100vw - 320px) / (1920 - 320)));
        object-fit: contain;
        margin: 0 auto;
        transition: all 0.3s ease-in-out;
      }

      span {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        margin-top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: -3px;
        display: block;
        color: $title-color;
      }
    }
  }
}